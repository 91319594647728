import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../services/api-services/api.service';
import { FinanceTransactionsPointCategories } from '../../utility/model/finance-transactions-point-summary-response';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpiringTransaction } from 'src/app/utility/model/finance-transactions-response';
import dayjs from 'dayjs';
import { showError } from 'src/app/utility/common-logic';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-flex-dollars-summary',
  templateUrl: './flex-dollars-summary.component.html',
  styleUrls: ['./flex-dollars-summary.component.scss'],
})
export class FlexDollarsSummaryComponent implements OnInit {
  @Input() override = '';
  @Input() flexDollarsAvailable: number | null | undefined = 0;
  @Input() flexDollarsEarned: number | null | undefined = 0;
  @Input() flexDollarsRedeemed: number | null | undefined = 0;
  @Input() pointCategories: FinanceTransactionsPointCategories[] | null;
  @Output() update = new EventEmitter();
  pointsExpiringThisMonth: ExpiringTransaction = {};
  start = dayjs().startOf('year').format('YYYY-MM-DD');
  end = dayjs().format('YYYY-MM-DD');
  @Input() set expiringTransactions(value: ExpiringTransaction[] | null) {
    const monthEnd = dayjs().endOf('month').format('YYYY-MM-DD');
    const yearEnd = dayjs().endOf('year').format('YYYY-MM-DD');
    this.pointsExpiringThisMonth = value.find((item: ExpiringTransaction) =>
      this.showYearlyExpiringPoints
        ? item.expiry_date === yearEnd
        : item.expiry_date === monthEnd,
    ) || { expiry_date: monthEnd, points: 0 };
  }
  loading = false;
  expiryPoint = environment.settings.showExpiringPoints;
  showYearlyExpiringPoints = environment.settings.showYearlyExpiringPoints;
  // pointHistoryShowPointSideBySide = environment.settings.pointHistoryShowPointSideBySide;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    public lang: LangService,
    private router: Router,
  ) {}

  async ngOnInit() {
    if (!this.override) {
      await this.getPointsSummary();
    }
  }

  private async getPointsSummary() {
    this.loading = true;
    this.flexDollarsAvailable = 0;
    this.flexDollarsEarned = 0;
    this.flexDollarsRedeemed = 0;
    await this.api
      .getPointsSummary()
      .then((res) => {
        this.flexDollarsAvailable = res.remaining_points;
        this.flexDollarsEarned = res.total_points;
        this.flexDollarsRedeemed = res.redeemed_points;
        this.pointCategories = res.point_categories;
      })
      .catch((err) => showError(err, this.toastr, this.translate))
      .finally(() => (this.loading = false));
  }

  updateTransactions(pointType: string, category: string) {
    const routeParams = this.route.snapshot.queryParamMap;
    this.start = routeParams.get('start');
    this.end = routeParams.get('end');
    this.update.emit({
      point_category: category,
      point_type: pointType,
      date_range: {
        start: this.start,
        end: this.end,
      },
    });
    this.router.navigateByUrl(
      `/point-history?point_type=${pointType}&point_category=all`,
    );
  }
}
