import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthData } from 'src/app/utility/model/token-auth';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

type FooterUserGuide = {
  lang: string;
  file: string;
};

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  authData: Partial<AuthData> = {};
  ver = environment.settings.ver;
  @Input() sticky = '';
  showFooterContactUsDialog = environment.settings.showFooterContactUsDialog;
  footerDynamicLogo = environment.settings.footer?.dynamicLogo;
  footerUserGuideLink = environment.settings.footer?.userGuide;
  footerPrivacyLink = environment.settings.footer?.privacyLink;
  footerTnCLink = environment.settings.footer?.tncLink;
  footerLogoLink = environment.settings?.footer?.logoLink || '/home';
  footerFaqLink = environment.settings.footer?.faqLink;
  footerRedemptionGuideLink = environment.settings.footer?.redemptionGuideLink;
  imgUrl = environment.url.img;
  showContactUS = environment.settings.footer?.showContactUS;
  showStaticContactUS = environment.settings.footer?.showStaticContactUS;
  showResource = environment.settings.footer?.showResource;
  showAbout = environment.settings.footer?.showAbout;
  contactEmail = environment.settings.contactEmail;
  contactNumber = environment.settings.contactNumber;
  showFooterOrgText = environment.settings.footer?.showFooterOrgText;
  showAboutRewardz = environment.settings.footer?.showAboutRewardz;
  showVersionAsHeading = environment.settings.footer?.showVersionAsHeading;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
  }

  get year() {
    return new Date().getFullYear();
  }

  get stickyClass() {
    return this.sticky ? 'sticky' : '';
  }

  openContactUs() {
    if (this.showFooterContactUsDialog) {
      this.dialog.open(ContactUsComponent, {
        maxWidth: '90vw',
        maxHeight: '90vh',
      });
    } else {
      this.router.navigate(['/about/contact-us']);
    }
  }

  isLink(link: FooterUserGuide[] | string | undefined) {
    if (Array.isArray(link)) {
      const link2: FooterUserGuide = link.find(
        (x) => x.lang === localStorage.getItem('lang'),
      );
      return (
        link2?.file.startsWith('https://') || link2?.file.startsWith('assets')
      );
    } else {
      return link?.startsWith('https://') || link?.startsWith('assets');
    }
  }

  get logo2() {
    if (
      !this.authData?.tokenAuth?.organization_details?.logo ||
      this.authData?.tokenAuth?.organization_details?.logo === this.imgUrl + '/'
    ) {
      return '';
    }
    return this.authData?.tokenAuth?.organization_details?.logo.startsWith(
      this.imgUrl,
    )
      ? this.authData?.tokenAuth?.organization_details?.logo
      : this.imgUrl + this.authData?.tokenAuth?.organization_details?.logo;
  }

  get footerUserGuideLink2() {
    if (Array.isArray(this.footerUserGuideLink)) {
      const link: FooterUserGuide = this.footerUserGuideLink.find(
        (x) => x.lang === localStorage.getItem('lang'),
      );
      return link.file;
    } else {
      return this.footerUserGuideLink;
    }
  }
}
